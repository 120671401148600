<template>
  <div id="HomePage">
    <HomeSwiper special="home" :is-home-page="true" :src="src"></HomeSwiper>
    <div id="whyChooseUs">
      <div class="whyChooseUs-title">
        <p>展厅布局</p>
        <p>点击相应场馆查看展商详情</p>
      </div>

      <div class="exhibitionCon">
        <div class="exhibitionList">
          <strong class="pointer" @click="handleRoom(17)">室内3号馆 :</strong
          >农业生产投入品专区
        </div>
        <div class="exhibitionList">
          <strong class="pointer" @click="handleRoom(18)">室内4号馆 :</strong
          >智慧农业专区
        </div>
        <div class="exhibitionList">
          <strong class="pointer" @click="handleRoom(19)">室内5号、</strong>
          <strong class="pointer" @click="handleRoom(20)">6号馆: </strong>农机零配件专区
        </div>
        <div class="exhibitionList">
          <strong class="pointer" @click="handleRoom(24)">室外中央广场区</strong
          >
          <strong class="pointer" @click="handleRoom(21)">及室外A/</strong>
          <strong class="pointer" @click="handleRoom(22)">B/C/</strong>
          <strong class="pointer" @click="handleRoom(23)">D/E/F展区: </strong>棉花机械、林果蔬菜机械、畜牧机械专区
        </div>
      </div>
      <div class="EXbox">
        <a
          v-for="el in roomData"
          :key="el.id"
          :class="el.info.class"
          @click="handleRoom(el.id)"
          :ex="el.position"
        >
        </a>

        <img src="/images/img.jpg" />
      </div>

      <div class="whyChooseUs-title">
        <p>公司展示</p>
      </div>
      <div class="exhibitsBox">
        <router-link
          class="exhibitsList"
          :to="{ path: '/cms/company', query: { areaId: item.areaId } }"
          v-for="item in cityData"
          :key="item.id"
        >
          <img :src="item.imageUrl" />
          <div class="name">{{ item.areaName }}</div>
          <!-- <div class="icon"></div> -->
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSwiper from "@/components/Cms/HomeSwiper.vue";
import { listByParent } from "@/api/cms/booth.js";
import { listAreaHome } from "@/api/cms/areahome.js";

import "@/styles/home.scss";

export default {
  name: "HomePage",
  components: { HomeSwiper },
  data() {
    return {
      src: "/images/banner.jpg",
      roomData: [{ info: {} }],
      cityData: [],
    };
  },
  methods: {
    handleRoom(id) {
      window.open(`/cms/room/${id}`);
    },
    listByParent() {
      listByParent(0).then((res) => {
        for (var i = 0; i < res.length; i++) {
          var item = res[i];
          let jsonConfig = JSON.parse(item.jsonConfig);
          item.info = jsonConfig;
        }
        this.roomData = res;
      });
    },
    listAreaHome() {
      listAreaHome(8).then((res) => {
        this.cityData = res;
      });
    },
  },
  created() {
    this.listByParent();
    this.listAreaHome();
  },
};
</script>
